@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Baumans&display=swap'); */

.swiper-button-next:after, .swiper-button-prev:after {
    color: #f44336 !important;
}

.swiper-slide {
    /* background-size: cover;
    background-position: center; */
    text-align: center;
  }

a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}

a:any-link {
    text-decoration: none;
    color: inherit;
}

a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }
